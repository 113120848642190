import React from 'react';
// import PropTypes from 'prop-types';

import { AppBar, Toolbar, Typography, makeStyles } from '@material-ui/core';
import Palette from 'components/Palette/Palette';
import styles from './ApplicationBar.module.css';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  toolbar: {
    background: theme.palette.primary,
    paddingRight: '5px',
  },
}));

const ApplicationBar = () => {
  const classes = useStyles();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.palette}>
          <Palette />
        </div>
        <AppBar position="static">
          <Toolbar classes={{ root: classes.toolbar }}>
            <Typography variant="h6" className={classes.title}>
              EXTRACTCOLOR.COM
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
};

ApplicationBar.propTypes = {};

export default ApplicationBar;
