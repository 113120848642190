import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      openAlert: false,
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
      openAlert: true,
    };
  }

  onCloseError() {
    this.setState({ openAlert: false });
  }

  render() {
    const { hasError, openAlert } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Snackbar open={openAlert} autoHideDuration={6000} onClose={() => this.onCloseError()}>
          <MuiAlert elevation={6} variant="filled" severity="error" onClose={() => this.onCloseError()}>
            Something went wrong!
          </MuiAlert>
        </Snackbar>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
