import { SAVE_COLORS, DELETE_COLOR, SAVE_COLORS_FOR_ANIMATION } from 'store/actionTypes/colorActionTypes';
import { REHYDRATE } from 'redux-persist/lib/constants';

const initialState = {
  colors: [],
  colorsForAnimation: [],
  pendingSync: false,
  pendingGet: false,
  pendingDelete: false,
  syncDone: false,
  error: null,
};

const filterForDuplicateColors = (storedColors, newColors) => {
  const newStoredColors = [...storedColors];

  const mapColors = {};
  newColors.forEach((newColor) => {
    mapColors[newColor] = true;
  });

  storedColors.forEach((color) => {
    if (mapColors[color]) {
      mapColors[color] = false;
    }
  });

  Object.keys(mapColors).forEach((newColor) => {
    if (mapColors[newColor]) newStoredColors.push(newColor);
  });

  return newStoredColors;
};

const deleteColor = (state, action) => {
  const newState = { ...state };

  switch (action.type) {
    case `${DELETE_COLOR}_SUCCESS`:
      newState.colors.splice(action.additionalData.index, 1);
      newState.pendingGet = false;
      break;
    default:
      break;
  }

  return newState;
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };

  if (action.type.indexOf(DELETE_COLOR) !== -1) {
    return deleteColor(state, action);
  }

  if (action.type === REHYDRATE) {
    newState.colors = action.payload && action.payload.colors ? action.payload.colors.colors : [];
  } else if (action.type === SAVE_COLORS) {
    newState.colors = filterForDuplicateColors(newState.colors, action.colors);
  } else if (action.type === SAVE_COLORS_FOR_ANIMATION) {
    newState.colorsForAnimation = action.colors;
  }

  return newState;
};

export default reducer;
