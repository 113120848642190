/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback, useEffect } from 'react';
import { Button, Grid, Divider, makeStyles, Badge } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { ReactComponent as UploadSvg } from 'svgs/upload.svg';
import { ReactComponent as RefreshSvg } from 'svgs/refresh.svg';
import { ReactComponent as SaveSvg } from 'svgs/save.svg';
import ColorBox from 'components/ColorBox/ColorBox';
import WithSvg from 'components/WithSvg/WithSvg';
import { SAVE_COLORS, SAVE_COLORS_FOR_ANIMATION } from 'store/actionTypes/colorActionTypes';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { motion } from 'framer-motion';
import { DELAY } from 'utils/constants';
import { useDropzone } from 'react-dropzone';
import styles from './ColorBoxGenerator.module.css';

const resetVariants = {
  rotate: {
    rotate: [0, -360],
    transition: {
      duration: 0.3,
    },
  },
};

const colorInitialState = ['empty', 'empty', 'empty', 'empty', 'empty', 'empty', 'empty', 'empty', 'empty'];
const selectedColorInitialState = ['', '', '', '', '', '', '', '', ''];

const useStyles = makeStyles(() => ({
  button: {
    flexGrow: 1,
    padding: '10px 0',
  },
  divider: {
    height: '70%',
    marginTop: '2%',
  },
  badge: {
    background: 'transparent',
    color: 'black',
    fontSize: '0.6em',
    right: '-7px',
    fontWeight: '400',
  },
}));

const getUnselectedColors = (generatedColors, selectedColors) => {
  const newGeneratedColors = [];

  for (let i = 0; i < generatedColors.length; i += 1) {
    if (generatedColors[i] === selectedColors[i]) {
      newGeneratedColors.push('empty');
    } else {
      newGeneratedColors.push(generatedColors[i]);
    }
  }

  return newGeneratedColors;
};

const ColorBoxGenerator = (props) => {
  const { setImagePreviewSrc, imageColors, setImageColors, setSearchValue, searchValue, imagePreviewSrc } = props;

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: 'image/jpeg, image/png',
  });

  const classes = useStyles();

  const [generatedColors, setGeneratedColors] = useState(colorInitialState);
  const [selectedColors, setSelectedColors] = useState(selectedColorInitialState);
  const [noSelectedColors, setNoSelectedColors] = useState(0);

  const [disabledReset, setDisabledReset] = useState(true);
  const [disabledSave, setDisabledSave] = useState(true);

  const [animateReset, setAnimateReset] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (imageColors.length > 0) {
      setGeneratedColors(imageColors);
    } else if (imageColors.length === 0) {
      setGeneratedColors(colorInitialState);
    }
  }, [imageColors, setGeneratedColors]);

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setImagePreviewSrc(URL.createObjectURL(acceptedFiles[0]));
    }
  }, [acceptedFiles, setImagePreviewSrc]);

  useEffect(() => {
    if (selectedColors.some((color) => color !== '')) {
      setDisabledSave(false);
    } else {
      setDisabledSave(true);
    }
  }, [selectedColors]);

  useEffect(() => {
    if (imageColors.length === 0 && searchValue === '' && imagePreviewSrc === '') {
      setDisabledReset(true);
    } else {
      setDisabledReset(false);
    }
  }, [imageColors.length, imagePreviewSrc, searchValue]);

  const uploadImage = useCallback(() => {
    setDisabledSave(true);
    setSelectedColors(selectedColorInitialState);
    setNoSelectedColors(0);
  }, []);

  const resetImage = useCallback(() => {
    setImagePreviewSrc('');
    setImageColors([]);
  }, [setImagePreviewSrc, setImageColors]);

  const resetState = useCallback(
    (resetColors = false, newGeneratedColors = undefined) => {
      if (newGeneratedColors) setGeneratedColors(newGeneratedColors);
      else setGeneratedColors(colorInitialState);

      setSelectedColors(selectedColorInitialState);
      setNoSelectedColors(0);
      setSearchValue('');

      if (resetColors) {
        resetImage();
      }
    },
    [resetImage, setSearchValue],
  );

  const onClickColor = useCallback(
    (index) => {
      const newSelectedColors = [...selectedColors];
      if (newSelectedColors[index]) {
        newSelectedColors[index] = '';
        setSelectedColors(newSelectedColors);
        if (noSelectedColors > 0) {
          setNoSelectedColors(noSelectedColors - 1);
        }
      } else {
        newSelectedColors[index] = generatedColors[index];
        setSelectedColors(newSelectedColors);
        setNoSelectedColors(noSelectedColors + 1);
      }
    },
    [generatedColors, selectedColors, noSelectedColors],
  );

  const saveColors = useCallback(
    (event) => {
      const validColors = selectedColors.filter((color) => color !== '');
      const newGeneratedColors = getUnselectedColors(generatedColors, selectedColors);
      if (validColors.length > 0) {
        dispatch({
          type: SAVE_COLORS_FOR_ANIMATION,
          colors: validColors,
        });
        if (event.screenX && event.screenX !== 0 && event.screenY && event.screenY !== 0) {
          dispatch({
            type: SAVE_COLORS,
            colors: validColors,
          });
        }
        resetState(false, newGeneratedColors);
      }
    },
    [dispatch, selectedColors, generatedColors, resetState],
  );

  const getColorBoxes = useCallback(() => {
    let delay = 0;
    const colorBoxes = [];

    for (let i = 0; i < generatedColors.length; i += 1) {
      const color = generatedColors[i];
      if (color === 'empty') {
        colorBoxes.push(
          <Grid item xs={4} key={`${color}${i}`}>
            <ColorBox color="#fff" isEmpty transitionDelay={delay} />
          </Grid>,
        );
      } else {
        colorBoxes.push(
          <Grid id={`colorBox${i}`} item xs={4} key={`${color}${i}`} onClick={() => onClickColor(i)}>
            <ColorBox
              selected={!!selectedColors[i]}
              color={color}
              transitionDelay={delay}
              style={{ cursor: 'pointer' }}
            />
          </Grid>,
        );
      }
      delay += DELAY;
    }
    return colorBoxes;
  }, [generatedColors, selectedColors, onClickColor]);

  const onReset = useCallback(() => {
    setAnimateReset(true);
    resetState(true);
    setTimeout(() => {
      setAnimateReset(false);
    }, 300);
  }, [resetState]);

  return (
    <ErrorBoundary>
      <div className={styles.container}>
        <div className={styles.header}>
          <Button disabled={disabledReset} onClick={onReset} classes={{ root: classes.button }} id="resetColors">
            <motion.div variants={resetVariants} animate={animateReset ? 'rotate' : ''}>
              <WithSvg disabled={disabledReset} component={RefreshSvg} size={20} />
            </motion.div>
          </Button>
          <Divider orientation="vertical" classes={{ root: classes.divider }} />
          <Button onClick={uploadImage} classes={{ root: classes.button }} id="uploadImage" {...getRootProps()}>
            <input {...getInputProps()} />
            <WithSvg component={UploadSvg} size={24} />
          </Button>
          <Divider orientation="vertical" classes={{ root: classes.divider }} />
          <Button disabled={disabledSave} classes={{ root: classes.button }} onClick={saveColors} id="saveColors">
            <Badge badgeContent={`(${noSelectedColors})`} classes={{ badge: classes.badge }}>
              <WithSvg disabled={disabledSave} component={SaveSvg} size={20} />
            </Badge>
          </Button>
        </div>
        <div className={styles.colorsContainer}>
          <Grid container spacing={2}>
            {getColorBoxes()}
          </Grid>
        </div>
      </div>
    </ErrorBoundary>
  );
};

ColorBoxGenerator.propTypes = {
  setImagePreviewSrc: PropTypes.func.isRequired,
  imageColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  setImageColors: PropTypes.func.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
};

export default ColorBoxGenerator;
