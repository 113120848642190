import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

import styles from './Spinner.module.css';

const Spinner = (props) => {
  const { smallHeight, className, size } = props;

  return (
    <div
      data-test="spinner-container"
      className={`${styles.container} ${smallHeight ? styles.smallHeight : ''} ${className}`}
    >
      <CircularProgress size={size} color="primary" />
    </div>
  );
};

Spinner.propTypes = {
  smallHeight: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.number,
};

Spinner.defaultProps = {
  smallHeight: false,
  className: '',
  size: undefined,
};

export default Spinner;
