import React, { useState, useCallback, useEffect } from 'react';
import PaletteModal from 'components/modals/PaletteModal/PaletteModal';
import { makeStyles, Button } from '@material-ui/core';
import WithSvg from 'components/WithSvg/WithSvg';
import { ReactComponent as PaletteSvg } from 'svgs/palette.svg';
import styles from './Palette.module.css';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  buttonRoot: {
    padding: 0,
  },
}));

const Palette = (props) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [background, setBackground] = useState('');

  const colorsForAnimation = useSelector((state) => state.colors.colorsForAnimation);

  useEffect(() => {
    if (colorsForAnimation.length > 0) {
      let background = 'linear-gradient(to left';
      let i = 0;
      const percent = (1 / colorsForAnimation.length) * 100;
      for (i = 0; i < colorsForAnimation.length - 1; i++) {
        const color = colorsForAnimation[i];
        background = `${background}, ${color} ${percent * i}%, ${color} ${percent * (i + 1)}%`;
      }
      const color = colorsForAnimation[i];
      background = `${background}, ${color} ${percent * i}%, ${color} 100%)`;
      setBackground(background);

      setTimeout(() => {
        setBackground('');
      }, 1200);
    }
  }, [colorsForAnimation]);

  const toggleModal = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <>
      <Button classes={{ root: classes.buttonRoot }} onClick={toggleModal}>
        <div className={styles.mainContainer}>
          <div className={styles.container}>
            <WithSvg component={PaletteSvg} size={24} />
          </div>
          <div
            className={`${styles.animationBox} ${background ? styles.addAnimation : styles.deleteAnimation}`}
            style={{ background: background }}
          ></div>
        </div>
      </Button>
      {isOpen && <PaletteModal open={isOpen} onClose={toggleModal} />}
    </>
  );
};

Palette.propTypes = {};

export default Palette;
