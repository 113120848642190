import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Fade, Typography, withWidth, Menu, MenuItem, IconButton } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';

import WithSvg from 'components/WithSvg/WithSvg';
import { ReactComponent as SaveSvg } from 'svgs/save.svg';
import ColorBox from 'components/ColorBox/ColorBox';
import { DELETE_COLOR } from 'store/actionTypes/colorActionTypes';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import Spinner from 'components/Spinner/Spinner';
import { jsPDF } from 'jspdf';
import styles from './PaletteModal.module.css';

const PaletteModal = (props) => {
  const { open, onClose, width } = props;

  const [deletedColor, setDeletedColor] = useState('');

  const colorsRedux = useSelector((state) => state.colors);

  const containerRef = useRef();

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    window.html2canvas = html2canvas;
  }, []);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onDelete = useCallback(
    (index) => {
      dispatch({
        type: `${DELETE_COLOR}_SUCCESS`,
        additionalData: {
          index,
        },
      });
    },
    [dispatch],
  );

  const prepareForDelete = useCallback((selectedColor) => {
    setDeletedColor(selectedColor);
  }, []);

  const onClickSaveAsText = useCallback(() => {
    const blob = new Blob([colorsRedux.colors.join('\n')], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, 'colors.txt');
  }, [colorsRedux.colors]);

  const onClickSaveImage = useCallback(() => {
    html2canvas(containerRef.current).then((canvas) => {
      canvas.toBlob((blob) => {
        saveAs(blob, 'colors.png');
      });
    });
  }, []);

  const onClickSavePdf = useCallback(() => {
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF('landscape', 'pt', 'legal');
    pdf.html(containerRef.current, {
      callback: () => {
        pdf.save('colors.pdf');
      },
      html2canvas: {
        width: 500,
      },
    });
  }, []);

  return (
    <ModalContainer open={open} onClose={onClose}>
      <div className={styles.saveIcon}>
        <IconButton onClick={handleClick} aria-controls="save-menu">
          <WithSvg component={SaveSvg} size={24} />
        </IconButton>
        <Menu id="save-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={onClickSaveAsText}>Save as Text</MenuItem>
          <MenuItem onClick={onClickSaveImage}>Save as Image</MenuItem>
          <MenuItem onClick={onClickSavePdf}>Save as PDF</MenuItem>
        </Menu>
      </div>
      <div className={styles.gridWrapper} ref={containerRef}>
        {colorsRedux.pendingGet || colorsRedux.pendingDelete || colorsRedux.pendingSync ? (
          <Spinner />
        ) : (
          <>
            {colorsRedux.colors.length > 0 ? (
              <Grid container spacing={2} justify={width === 'xs' ? 'center' : 'flex-start'}>
                {colorsRedux.colors.map((color, index) => (
                  <Fade key={color} in={deletedColor !== color} onExited={() => onDelete(index)}>
                    <Grid item md={2} sm={3} xs={5}>
                      <ColorBox color={color} showIcons onDelete={() => prepareForDelete(color)} />
                    </Grid>
                  </Fade>
                ))}
              </Grid>
            ) : (
              <Typography variant="h6" align="center">
                There are no colors saved
              </Typography>
            )}
          </>
        )}
      </div>
    </ModalContainer>
  );
};

PaletteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
};

export default withWidth()(PaletteModal);
