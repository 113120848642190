/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { validURL } from 'utils/helpers';

import { ReactComponent as CameraSvg } from 'svgs/camera.svg';
import { ReactComponent as ImageDragSvg } from 'svgs/image-drag.svg';
import WithSvg from 'components/WithSvg/WithSvg';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { TextField, Button, makeStyles, Typography, Popover, Grid } from '@material-ui/core';
import useHttp from 'hooks/useHttp';
import Spinner from 'components/Spinner/Spinner';
import { useDispatch } from 'react-redux';
import { SEND_NOTIFICATION } from 'store/actionTypes/notificationActionTypes';
import styles from './ImageBox.module.css';

const useStyles = makeStyles(() => ({
  inputRoot: {
    height: '45px',
    background: '#fff',
    boxShadow: '0 0.1rem 0.3rem rgba(0, 0, 0, 0.2)',
    border: 'none',
  },
  buttonRoot: {
    boxShadow: '0 0.1rem 0.3rem rgba(0, 0, 0, 0.2)',
    background: '#fff',
    marginLeft: '10px',
  },
}));

const ImageBox = (props) => {
  const { imagePreviewSrc, setImagePreviewSrc, imageRef, setImageColors, searchValue, setSearchValue } = props;
  const classes = useStyles();

  const dispatch = useDispatch();

  const [imagesUrls, setImagesUrls] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [loading, setLoading] = useState(true);

  const http = useHttp();

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg, image/png',
  });

  const onChangeSearchValue = useCallback(
    (event) => {
      setSearchValue(event.target.value);
    },
    [setSearchValue],
  );

  const handlePopoverOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onClickButton = useCallback(
    (event) => {
      const link = `https://web-screenshot-maker.herokuapp.com/?url=${searchValue}`;
      if (validURL(searchValue)) {
        if (link !== imagePreviewSrc) {
          setLoading(true);
          setImageColors([]);
          setImagePreviewSrc(link);
        } else {
          dispatch({
            type: SEND_NOTIFICATION,
            message: 'Colors already extracted for this one!',
            status: 'warning',
            hideDuration: 5000,
          });
        }
      } else {
        http.sendRequest('get', [
          `https://api.unsplash.com/search/photos?query=${searchValue}`,
          {
            headers: {
              Authorization: 'Client-ID L9CHfCsbTeQQO-g5PliPTaka4x3wQ3odJtxwz_GPj7g',
            },
          },
        ]);
        handlePopoverOpen(event);
      }
    },
    [dispatch, handlePopoverOpen, http, imagePreviewSrc, searchValue, setImageColors, setImagePreviewSrc],
  );

  const onClickImage = useCallback(
    (index) => {
      setImagePreviewSrc(imagesUrls[index]);
      handlePopoverClose();
    },
    [handlePopoverClose, imagesUrls, setImagePreviewSrc],
  );

  const onLoadImage = useCallback(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setImagePreviewSrc(URL.createObjectURL(acceptedFiles[0]));
    }
  }, [acceptedFiles, setImagePreviewSrc]);

  useEffect(() => {
    if (http.data) {
      setImagesUrls(http.data.results.slice(0, 9).map((el) => el.urls.small));
    }
  }, [http.data]);

  const open = Boolean(anchorEl);

  return (
    <>
      <ErrorBoundary>
        <div className={styles.container}>
          <div className={styles.imageContainer} {...getRootProps()}>
            <input {...getInputProps()} className={styles.dropInput} />
            {imagePreviewSrc && (
              <>
                <div style={{ display: loading ? 'block' : 'none' }}>
                  <Spinner />
                </div>
                <div style={{ display: loading ? 'none' : 'block' }}>
                  <img alt="preview" src={imagePreviewSrc} ref={imageRef} onLoad={onLoadImage} />
                </div>
              </>
            )}
            {imagePreviewSrc === '' && (
              <div>
                <WithSvg component={ImageDragSvg} size={100} />
                {isDragActive && <Typography align="center">Drag here!</Typography>}
              </div>
            )}
          </div>
          <div className={styles.bottomContainer}>
            <TextField
              variant="outlined"
              placeholder="enter your URL/search a word"
              id="text-url-input"
              InputProps={{ classes: { root: classes.inputRoot } }}
              fullWidth
              value={searchValue}
              onChange={onChangeSearchValue}
            />
            <Button id="search-button" classes={{ root: classes.buttonRoot }} onClick={onClickButton}>
              <WithSvg component={CameraSvg} size={23} />
            </Button>
            <Popover
              id="mouse-over-popover"
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <div className={styles.popupImageBox}>
                {http.isLoading ? (
                  <Spinner />
                ) : (
                  <Grid container spacing={2}>
                    {imagesUrls.map((url, index) => (
                      <Grid id={`unsplash-image-${index}`} key={url} item xs={4} onClick={() => onClickImage(index)}>
                        <div className={styles.image}>
                          <img alt={index} src={url} />
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </div>
            </Popover>
          </div>
        </div>
      </ErrorBoundary>
    </>
  );
};

ImageBox.propTypes = {
  imagePreviewSrc: PropTypes.string.isRequired,
  setImagePreviewSrc: PropTypes.func.isRequired,
  imageRef: PropTypes.objectOf(PropTypes.object).isRequired,
  setImageColors: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
};

export default ImageBox;
