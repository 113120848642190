import React from 'react';
// import PropTypes from 'prop-types';
import styles from './MouseCursor.module.css';
import './MouseCursor.css';

const MouseCursor = () => {
  return (
    <>
      <div
        id="overlay"
        style={{
          display: 'none',
          height: '100%',
          width: '100%',
          background: 'black',
          zIndex: '100',
          opacity: '0.15',
          position: 'absolute',
          transition: 'all 0.3s ease-in',
        }}
      />
      <div id="cursor" style={{ display: 'none', position: 'absolute', zIndex: 1000, transition: 'all 1.2s ease-out' }}>
        <div className={styles.cursorContainer}>
          <div className={styles.cursorWrapper}>
            <div className={styles.cursor} id="cursorWrapper" />
          </div>
          <img
            src="http://cdn1.iconfinder.com/data/icons/CrystalClear/22x22/actions/14_select.png"
            alt="mouse"
            className={styles.cursorImage}
          />
        </div>
      </div>
    </>
  );
};

MouseCursor.propTypes = {};

export default MouseCursor;
