import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Vibrant from 'node-vibrant';

import ColorBoxGenerator from 'components/ColorBoxGenerator/ColorBoxGenerator';
import ImageBox from 'components/ImageBox/ImageBox';
import chroma from 'chroma-js';
import { shuffle } from 'utils/helpers';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { useDispatch } from 'react-redux';
import { SEND_NOTIFICATION } from 'store/actionTypes/notificationActionTypes';
import HelpButton from 'components/HelpButton/HelpButton';
import styles from './Main.module.css';

const Main = (props) => {
  const { setAppKey } = props;

  const [imagePreviewSrc, setImagePreviewSrc] = useState('');
  const [imageColors, setImageColors] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const imageRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    if (imagePreviewSrc) {
      Vibrant.from(imagePreviewSrc)
        .getPalette()
        .then((palette) => {
          const colors = [
            palette.DarkMuted.hex,
            palette.DarkVibrant.hex,
            palette.LightMuted.hex,
            palette.LightVibrant.hex,
            palette.Muted.hex,
            palette.Vibrant.hex,
          ];
          setImageColors(
            shuffle([
              ...colors,
              chroma.average(colors).hex(),
              chroma.average(colors, 'lab').hex(),
              chroma.average(colors, 'lch').hex(),
            ]),
          );
        })
        .catch(() => {
          dispatch({
            type: SEND_NOTIFICATION,
            message: "Can't extract colors from this one. Sorry!",
            status: 'warning',
            hideDuration: 10000,
          });
        });
    }
  }, [dispatch, imagePreviewSrc, setImageColors]);

  return (
    <ErrorBoundary>
      <div className={styles.helpButton}>
        <HelpButton setAppKey={setAppKey} helpFunctions={{ setSearchValue }} />
      </div>
      <div className={styles.container}>
        <div className={styles.subContainer}>
          <div className={styles.imageBox}>
            <ImageBox
              setImageColors={setImageColors}
              imageRef={imageRef}
              imagePreviewSrc={imagePreviewSrc}
              setImagePreviewSrc={setImagePreviewSrc}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
          </div>
          <div className={styles.colorBox}>
            <ColorBoxGenerator
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              imagePreviewSrc={imagePreviewSrc}
              imageColors={imageColors}
              setImageColors={setImageColors}
              setImagePreviewSrc={setImagePreviewSrc}
            />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

Main.propTypes = {
  setAppKey: PropTypes.func.isRequired,
};

export default Main;
