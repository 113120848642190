import { createMuiTheme } from '@material-ui/core/styles';
import { PRIMARY_COLOR } from 'utils/constants';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
  },
});

export default theme;
