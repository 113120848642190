import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import WithSvg from 'components/WithSvg/WithSvg';
import { ReactComponent as QuestionSvg } from 'svgs/question.svg';
import { IconButton } from '@material-ui/core';
import { NO_RANDOM_COLORS, DELAY } from 'utils/constants';

const TIMEOUT_BEFORE_CLICK = 1200;
const TIME_WHEN_CLICKING = 500;
const DEFAULT_WRITE_STEP = 40;

const getInitElements = () => {
  const cursor = document.getElementById('cursor');
  cursor.style.display = 'block';
  const overlay = document.getElementById('overlay');
  overlay.style.display = 'block';
  const cursorWrapper = document.getElementById('cursorWrapper');

  return { cursor, cursorWrapper };
};

const moveToClick = (cursor, cursorWrapper, id) => () => {
  const moveElement = document.getElementById(id);
  if (moveElement) {
    const boundaries = moveElement.getBoundingClientRect();
    // eslint-disable-next-line no-param-reassign
    cursor.style.transform = `translate(${boundaries.x + boundaries.width / 2}px, ${
      boundaries.y + boundaries.height / 2
    }px)`;
    setTimeout(() => {
      moveElement.click();
      cursorWrapper.classList.add('expand');
      setTimeout(() => {
        cursorWrapper.classList.remove('expand');
      }, TIME_WHEN_CLICKING);
    }, TIMEOUT_BEFORE_CLICK);
  } else {
    setTimeout(() => {
      setTimeout(() => {}, TIME_WHEN_CLICKING);
    }, TIME_WHEN_CLICKING);
  }
};

const runSimulation = (array, time) => {
  const intervalIds = [];
  array[0]();
  for (let i = 1; i < array.length; i += 1) {
    const element = array[i];
    if (element.func) {
      intervalIds.push(
        setTimeout(() => {
          element.func();
        }, element.wait),
      );
    } else {
      intervalIds.push(
        setTimeout(() => {
          element();
        }, i * time),
      );
    }
  }

  return intervalIds;
};

const getRandomSelections = () => {
  const numbers = [];

  while (numbers.length !== 3) {
    const randomNumber = Math.floor(Math.random() * 10);

    if (numbers.includes(randomNumber)) {
      // eslint-disable-next-line no-continue
      continue;
    } else {
      numbers.push(randomNumber);
    }
  }

  return numbers;
};

const HelpButton = (props) => {
  const { setAppKey, helpFunctions } = props;

  const [intervalIds, setIntervalIds] = useState();

  useEffect(() => {
    const overlay = document.getElementById('overlay');
    const cursor = document.getElementById('cursor');
    const onClickOverlay = () => {
      overlay.style.display = 'none';
      cursor.style.display = 'none';
      cursor.style.transform = 'translate(0, 0)';

      intervalIds.forEach((interval) => {
        clearTimeout(interval);
      });
      setAppKey(`${Math.random()}`);
    };
    overlay.addEventListener('click', onClickOverlay);

    return () => {
      overlay.removeEventListener('click', onClickOverlay);
    };
  }, [intervalIds, setAppKey]);

  const resetHelp = useCallback(() => {
    const overlay = document.getElementById('overlay');
    const cursor = document.getElementById('cursor');
    cursor.style.display = 'none';
    overlay.style.display = 'none';
  }, []);

  const writeToInput = useCallback(
    (value, step = DEFAULT_WRITE_STEP) => {
      for (let i = 1; i <= value.length; i += 1) {
        setTimeout(() => {
          helpFunctions.setSearchValue(value.slice(0, i));
        }, (i + 1) * step);
      }
    },
    [helpFunctions],
  );

  const onClickButton = useCallback(() => {
    const { cursor, cursorWrapper } = getInitElements();
    const time = TIMEOUT_BEFORE_CLICK + TIME_WHEN_CLICKING;
    const randomSelections = getRandomSelections();

    const generateRandomColorTime = NO_RANDOM_COLORS * DELAY;

    const intervals = runSimulation(
      [
        () => {
          moveToClick(cursor, cursorWrapper, 'text-url-input')();
          setTimeout(() => {
            document.getElementById('text-url-input').focus();
          }, TIMEOUT_BEFORE_CLICK + 200);
        },
        {
          func: () => {
            writeToInput('car');
          },
          wait: time,
        },
        moveToClick(cursor, cursorWrapper, 'search-button'),
        {
          func: () => {},
          wait: 2 * time,
        },
        {
          func: moveToClick(cursor, cursorWrapper, `unsplash-image-6`),
          wait: 7 * time,
        },
        {
          func: moveToClick(cursor, cursorWrapper, `colorBox${randomSelections[0]}`),
          wait: 8 * time + generateRandomColorTime,
        },
        {
          func: moveToClick(cursor, cursorWrapper, `colorBox${randomSelections[1]}`),
          wait: 9 * time + generateRandomColorTime,
        },
        {
          func: moveToClick(cursor, cursorWrapper, `colorBox${randomSelections[2]}`),
          wait: 10 * time + generateRandomColorTime,
        },
        {
          func: moveToClick(cursor, cursorWrapper, 'saveColors'),
          wait: 11 * time + generateRandomColorTime,
        },
        {
          func: moveToClick(cursor, cursorWrapper, 'resetColors'),
          wait: 12 * time + generateRandomColorTime,
        },
        {
          func: () => {
            moveToClick(cursor, cursorWrapper, 'text-url-input')();
            setTimeout(() => {
              document.getElementById('text-url-input').focus();
            }, TIMEOUT_BEFORE_CLICK + 200);
          },
          wait: 13 * time + generateRandomColorTime,
        },
        {
          func: () => {
            writeToInput('google.com');
          },
          wait: 14 * time + generateRandomColorTime,
        },
        {
          func: moveToClick(cursor, cursorWrapper, 'search-button'),
          wait: 15 * time + generateRandomColorTime,
        },
        {
          func: moveToClick(cursor, cursorWrapper, `colorBox${randomSelections[0]}`),
          wait: 21 * time + 2 * generateRandomColorTime,
        },
        {
          func: moveToClick(cursor, cursorWrapper, `colorBox${randomSelections[1]}`),
          wait: 22 * time + 2 * generateRandomColorTime,
        },
        {
          func: moveToClick(cursor, cursorWrapper, `colorBox${randomSelections[2]}`),
          wait: 23 * time + 2 * generateRandomColorTime,
        },
        {
          func: moveToClick(cursor, cursorWrapper, 'saveColors'),
          wait: 24 * time + 2 * generateRandomColorTime,
        },
        {
          func: moveToClick(cursor, cursorWrapper, 'resetColors'),
          wait: 25 * time + 2 * generateRandomColorTime,
        },
        {
          func: () => {
            resetHelp();
          },
          wait: 26 * time + 2 * generateRandomColorTime,
        },
      ],
      time,
    );
    setIntervalIds(intervals);
  }, [resetHelp, writeToInput]);

  return (
    <IconButton onClick={onClickButton}>
      <WithSvg component={QuestionSvg} />
    </IconButton>
  );
};

HelpButton.propTypes = {
  setAppKey: PropTypes.func.isRequired,
  helpFunctions: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default HelpButton;
